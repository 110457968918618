/*======================
    Header Nav Bar 
========================*/

.mainmenu-nav {
    .mainmenu{
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        li{
            display: inline-block;
            position: relative;
            a{
                padding-top: 52px;
                padding-bottom: 52px;
                padding-left: 20px;
                padding-right: 20px;
                color: #ffffff;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 1.75px;
                line-height: 1.18;
                text-transform: uppercase;
                display: inline-block;
                @media #{$lg-layout} {
                    padding-left: 18px;
                    padding-right: 18px;
                }
                span{
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    &::after{
                        content: '';
                        width: 0;
                        height: 1px;
                        bottom: 0;
                        position: absolute;
                        left: auto;
                        right: 0;
                        z-index: -1;
                        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                        background: currentColor;
                    }
                }
            }
            &:hover{
                a{
                    color: #ffffff;
                    span{
                        &::after{
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}





.header{
    &.color--black {
        .mainmenu-nav{
            .mainmenu{
                > li{
                    > a{
                        color: #ababab;
                    }
                    &:hover{
                        > a{
                            color: $theme-color;
                        }
                    }
                }
            }
        }  
    }
}










