// Animation Css
.link-hover{
    position: relative;
    &::after{
        content: '';
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: -1;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: currentColor;
    }
    &:hover{
        &::after{
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}



/* Slick Default Dot */

.slick-default-dot {
    position: relative;
    ul {
        &.slick-dots {
            position: absolute;
            bottom: 70px;
            z-index: 1;
            @media #{$sm-layout} {
                bottom: 30px;
            }
            @media #{$md-layout} {
                bottom: 30px;
            }
            li {
                width: auto;
                height: auto;
                button {
                    width: 10px;
                    height: 10px;
                    padding: 5px;
                    border: 0;
                    outline: none;
                    background: $body-color;
                    border-radius: 100%;
                    &::before{
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background: $theme-color;
                    }
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 2;
        @media #{$sm-layout} {
            display: none !important;
        }
        &::before {
            font-size: 30px;
            opacity: 1;
        }
    }
    .slick-prev{
        left: 30px;
    }
    .slick-next {
        right: 30px;
    }
}




/* ------------------------
    Separator Bounce 
---------------------------*/

@-webkit-keyframes separator-bounce {
    0%,
    80%,
    to {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes separator-bounce {
    0%,
    80%,
    to {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}






