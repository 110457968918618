// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/css/revolution.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-modal-video/scss/modal-video.scss';
@import '~lightgallery.js/dist/css/lightgallery.css';
@import "~react-tabs/style/react-tabs.css";
@import "~animate.css/animate.min.css";

@font-face {
    font-family: 'Cremona Sans Serif';
    src: url('../public/assets/fonts/cremona/webfont/cremona_regular_ttf-webfont.woff') format('woff'),
         url('../public/assets/fonts/cremona/cremona_regular_TTF.ttf') format('truetype');
    /* Add additional font formats if available */
    /* Make sure to adjust the file paths based on your folder structure */
    /* You can also include font-weight and font-style properties if needed */
  }
  
  body {
    /* Apply the font to the body or any other desired element */
    font-family: 'Cremona Sans Serif', sans-serif;
  }

  .old-font {
    font-family: 'Rubik', sans-serif;
  }
.gap-5 {
    gap: 5px;
}
.hr-variant--1{
    background-image: linear-gradient(to right, #fd807f, #fea487)!important;
}
.footer-top{
    background-color: #fd837f!important;
}
.footer-social{
    display: flex;
    img{
        width: 20.2px;
        height: 20.2px;
    }
}
.inner-custom-hr{
    color: #ffffff!important;
    a {
        color: #ffffff!important;
    }
}

.copyright-style--1{
    border-top-color: #ff5c5c;
    background-color: #ff5c5c;
    color: #fff;
}
.header-btn-variant-1{
    display: flex;
    padding: 2px 18px;
    border-radius: 19px;
    border: solid 1px #585858;
    margin: 0px 10px;

    .br-1 {
        border-right: 1px solid;
        height: 100%;
    }
    button{
        margin: 0;
        outline: none;
        border-top: none;
        border-bottom: none;
        border-left: none;
        border-right: none;

    }
}
.header-btn-variant-2{
    display: flex;
    color: #fd3472;
    padding: 2px 18px;
    border-radius: 19px;
    border: solid 1px #fd3472;
    margin: 0px 10px;
    .br-1 {
        border-right: 1px solid;
        height: 100%;
    }
    button{
        margin: 0;
        outline: none;
        border-top: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
        color: #fd3472;
        
    }
}
.lang-active{
    color: #fd3472!important;

}
.mainmenu-nav{
    .mainmenu{
        text-align: left;
    }
}
.slick-thumb-custom{
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.custom-text-white {
    color: #ffffff!important;
}
.custom-text-black {
    color: #434343!important;
}
.custom-text-grey {
    color: #9f9f9f!important;
}
.app-text-color-primary{
    color: #ff6362
}
.app-bg-primary{
    background-color: #fd7c7d;
}
.download-clickable{
    padding: 0;
    outline: none;
    border: none;
}

.tab-style--2 .tab2-content .title img {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 32px;
    border-radius: 15px;
    // color: #fff;
    // background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
    // box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
    margin-right: 30px;
}

.mail-success{
    color: green;
    margin-top: 10px;
}

#mvArea {
    animation: zoominoutsinglefeatured 5s infinite ;
    ul {
        list-style-type:none;
        padding: 0;
        .li-img-container {
            img {
                width: 100vw;
                height: 100vh;
            }
        }
    }
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1.2,1.2);
    }
    50% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(1.2,1.2);
    }
}

.slide-container img {
    animation: zoominoutsinglefeatured 15s infinite ;
}

.slide-container {
    width: 100vw;
    height: calc(100vh - 100px);
    transition: all .3s;
}
.hr-main-banner {
    height: 100vh;
}

.body-scroll {
    .hr-main-banner {
        height: 100%;
        width: 50%;
    }
    .slider-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .scrollNavWrap {
        display: none;
    }
    .slider-text {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.slide-container.body-scroll {
    margin-top: 200px;
}

.scrollNavWrap {
    position: absolute;
    width: 30px;
    height: 130px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    animation: startTxtArea 2.1s;
    z-index: 999999999;
    .scrollNav {
        position: absolute;
        top: 0;
        color: #fff;
        font-size: 11px;
        animation: equallyBound 1.4s ease infinite;
        z-index: 999999999;
    }
}
.scrollNavWrap .scrollNav:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 80px;
    background-color: #fff;
    left: 14px;
    bottom: -113px;
    animation: lineMove .9s ease-in-out infinite;
    z-index: 999999999;
}
@keyframes lineMove {
    from {top: 20px;}
    to {top: 200px;}
  }