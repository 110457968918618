.hr-main-banner{
    background-image: linear-gradient(to bottom, #fd807f, #fd9483 50%, #ffe695);
}
.bg-color-hr{
    background-image: linear-gradient(to right, #fd807f, #fd9483 50%, #ffe695);
}
.hr-faq-head{
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #121212;
}
.donwload-btns{
    display: flex;
    justify-content: space-evenly;
}