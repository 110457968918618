/*========================
    Pricing Table 
==========================*/



.pricing-table {
    position: relative;
    height: 100%;
    background-color: #fff;
    padding: 34px 20px 40px;
    border: 1px solid #dce6ed;
    border-radius: 5px;
    text-align: center;
    @extend %transition;
    overflow: hidden;

    .feature-inner-mark{
        position: absolute;
        top: -1px;
        right: -1px;
        width: 0;
        height: 0;
        border-top: 88px solid #ee7455;
        border-bottom: 88px solid transparent;
        border-left: 88px solid transparent;
        span{
            position: absolute;
            top: -72px;
            right: 6px;
            text-align: center;
            font-size: 11px;
            font-weight: 700;
            line-height: 1.19;
            display: block;
            color: #fff;
            transform: rotate(45deg);
        }
    }

    .pricing-header{
        margin-bottom: 25px;
        .image{
            margin-bottom: 17px;
        }
        h5{
            &.title{
                font-size: 24px;
                line-height: 1.5;
                color: #333;
                margin-bottom: 19px;
            }
        }

        .pricing-wrap {
            .price-wrap-inner {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                h6{
                    &.currency{
                        font-size: 15px;
                        margin-bottom: 0;
                        color: #ababab;
                    }
                    &.price{
                        // font-size: 56px;
                        font-size: 18px;
                        line-height: .8;
                        margin: 0 4px;
                        font-weight: 300;
                        color: #333;
                    }
                    &.period{
                        font-size: 15px;
                        margin-bottom: 0;
                        color: #ababab;
                        align-self: flex-end;
                    }
                }
            }
        }

    }
    .pricing-content{
        ul{
            &.pricingfeature{
                @extend %liststyle;
                li{
                    position: relative;
                    display: block;
                    padding: 5px 0;
                }
            }
        }
    }
    .pricing-footer{
        padding-top: 32px;
    }
    &:hover{
        box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
        border: 1px solid transparent;
        transform: translateY(-5px);
    }
}



/*---------------------------
Pricing Table Style Two 
-----------------------------*/
.pricing-table--3{
    z-index: 2;
    position: relative;
    .inner{
        position: relative;
        height: 100%;
        margin: 0 auto;
        background-color: #fff;
        padding: 49px 20px 51px;
        border-radius: 5px;
        box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
        @extend %transition;
        overflow: hidden;
    }
    .pricing-header{
        z-index: 1;
        position: relative;
        margin-bottom: 25px;
        text-align: center;
        .image{
            margin-bottom: 17px;
        }
        .title{
            font-size: 15px;
            line-height: 1.5;
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 21px;
        }
        .price-wrapper-inner{
            display: flex;
            align-items: center;
            justify-content: center;
            h6{
                &.currency {
                    font-size: 48px;
                    line-height: .8;
                    margin: 0;
                    font-weight: 500;
                    color: #333;
                }
                &.price {
                    font-size: 48px;
                    line-height: .8;
                    margin: 0;
                    font-weight: 500;
                    color: #333;
                }
            }
        }
    }
    .pricing-button{
        text-align: center;
        padding-top: 40px;
        .ar-button{
            display: inline-block;
            width: 180px;
            max-width: 100%;
            padding: 0 30px;
            height: 48px;
            line-height: 48px;
            font-size: 14px;
        }
    }

    .pricing-content{
        text-align: left;
        max-width: 270px;
        margin: 29px auto 0;
        ul{
            &.pricingfeature{
                @extend %liststyle;
                li{
                    position: relative;
                    display: block;
                    padding: 5px 0;
                }
            }
        }
    }
    &.heighlight {
        .inner {
            padding: 52px 20px 61px;
            .feature-mark {
                position: absolute;
                top: 0;
                right: 0;
                height: 92px;
                width: 92px;
                background-image: linear-gradient(-188deg, #ffbe00 0%, #f76b1c 100%);
                span {
                    position: absolute;
                    top: 20px;
                    right: 7px;
                    text-align: center;
                    font-size: 11px;
                    font-weight: 700;
                    line-height: 1.19;
                    display: block;
                    color: #fff;
                    transform: rotate(45deg);
                    width: 50px;
                }
                &::after{
                    transform: rotate(-45deg);
                    background: none repeat scroll 0 0 #fff;
                    content: "";
                    height: 159px;
                    right: 23px;
                    position: absolute;
                    top: 10px;
                    width: 100%;
                }
            }
        }
    }
    &:hover{
        .inner{
            transform: translateY(-5px);
            box-shadow: 0 0px 41px rgba(51, 51, 51, 0.07);
        }
    }
}


/*---------------------------
Pricing Table Style Four 
-----------------------------*/
.pricing-table--4 {
    z-index: 2;
    position: relative;
    .inner {
        position: relative;
        height: 100%;
        margin: 0 auto;
        padding: 49px 20px 51px;
        border-radius: 8px;
        overflow: hidden;
        @extend %transition;
        text-align: center;
        z-index: 2;
        &::before {
            transition: opacity .3s, visibility .3s;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 101%;
            height: 101%;
            display: block;
            background-image: linear-gradient(-180deg, #681f9d 0%, #32178a 50%, #040747 100%);
            opacity: 0;
            visibility: hidden;
            z-index: -1;
            @extend %transition;
        }
        .feature-mark {
            position: absolute;
            top: 0;
            right: 0;
            height: 90px;
            width: 140px;
            &::before {
                content: '';
                position: absolute;
                top: -30px;
                right: -52px;
                height: 90px;
                width: 140px;
                transform: rotate(45deg);
                background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
            }
            span {
                position: absolute;
                top: 20px;
                right: 7px;
                text-align: center;
                font-size: 11px;
                font-weight: 700;
                line-height: 1.19;
                display: block;
                color: #fff;
                transform: rotate(45deg);
                width: 50px;
            }
        }
    }
    .pricing-header {
        .image {
            margin-bottom: 17px;
        }
        .title {
            font-size: 24px;
            line-height: 1.5;
            color: #ffff;
            margin-bottom: 18px;
        }
    }
    .pricing-content {
        p {
            text-align: center;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .pricing-button{
        text-align: center;
        padding-top: 40px;
        .ar-button{
            display: inline-block;
            width: 180px;
            max-width: 100%;
            padding: 0 30px;
            height: 48px;
            line-height: 48px;
            font-size: 14px;
        }
    }
    &.heighted{
        .inner{
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &:hover{
       .inner{
            transform: translateY(-5px);
            &::before{
                opacity: 1;
                visibility: visible;
            }
       }
    }
}



/*---------------------------
Pricing Table Style Five
-----------------------------*/

.pricing-box--5{
    height: 100%;
    .inner{
        position: relative;
        height: 100%;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px 30px 101px;
        border-radius: 4px;
        box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
        overflow: hidden;
        @extend %transition;
        text-align: center;
        .thumbnail{
            margin-bottom: 28px;
        }
        .content{
            h4{
                &.title{
                    text-transform: uppercase;
                    font-size: 18px;
                    letter-spacing: 2px;
                    line-height: 1.56;
                    margin-bottom: 14px;
                }
            }
            .feature-list{
                .pricing-feature-list{
                    @extend %liststyle;
                    color: #6d70a6;
                    li{
                        position: relative;
                        display: block;
                        padding: 7px 0;
                    }
                }
            }
        }
        .pricing-footer{
            position: absolute;
            bottom: 56px;
            left: 30px;
            right: 30px;
            button{
                background: transparent;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.23;
                position: relative;
                z-index: 1;
                padding-bottom: 2px;
                color: #38cb89;
                border: 0 none;
                padding-left: 0;
                padding-right: 0;

                &::after{
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: 0;
                    position: absolute;
                    left: auto;
                    right: 0;
                    z-index: -1;
                    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                    background: currentColor;
                }
                &:hover{
                    &::after{
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
    }
    &:hover{
        .inner{
            transform: translateY(-5px);
            box-shadow: 0 15px 40px rgba(51, 51, 51, 0.1);
        }
    }
}




