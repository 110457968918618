/*====================
Popup Video 
=====================*/

.popup-video-inner{
    .video-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.button-right {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translate(-50%, -50%);
            @media #{$sm-layout} {
                left: 50%;
            }
        }
    }
}
.video-popup {
    width: 60px;
    height: 60px;
    background: transparent;
    border: 4px solid #fff;
    position: relative;
    transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
    border-radius: 50%;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    outline: none;
    span {
        &.play-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1;
            margin-left: 1px;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                transform: translate(-50%, -50%);
                border-top: 11px solid transparent;
                border-bottom: 11px solid transparent;
                border-left: 17px solid #fff;
            }
        }
    }
    &.icon-large {
        width: 72px;
        height: 72px;
        background: transparent;
        border: 6px solid #fff;
    }
    &.button-solid {
        width: 120px;
        height: 120px;
        background: #fff;
        border: 0 none;
        @media #{$sm-layout} {
            width: 70px;
            height: 70px;
        }
        span{
            &.play-icon{
                &::before{
                    border-top-width: 12px;
                    border-bottom-width: 12px;
                    border-left-width: 19px;
                    border-left-color: #ee7455;
                }
            }
        }
    }
    &.button-solid-blue {
        width: 100px;
        height: 100px;
        background: #fff;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
        border: 0 none;
        @media #{$sm-layout} {
            width: 70px;
            height: 70px;
        }
        span{
            &.play-icon{
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    transform: translate(-50%, -50%);
                    border-top: 11px solid transparent;
                    border-bottom: 11px solid transparent;
                    border-left: 17px solid #fd1c25;
                }
            }
        }
    }

    &.button-solid-yellow {
        width: 78px;
        height: 78px;
        background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
        box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
        border: 0 none;
        @media #{$sm-layout} {
            width: 70px;
            height: 70px;
        }
        span{
            &.play-icon{
                &::before{
                    border-top-width: 9px;
                    border-bottom-width: 9px;
                    border-left-width: 14px;

                }
            }
        }
    }
    &:hover{
        transform: translate(-50%, -50%) scale3d(1.15, 1.15, 1.15);
    }
}


.video-marker{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
    pointer-events: none;
    .wave-pulse{
        &::before ,
        &::after{
            opacity: 0;
            content: '';
            display: block;
            position: absolute;
            width: 200px;
            height: 200px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            border: 3px solid #ee7455;
            animation: zoomBig 3.25s linear infinite;
        }
        &.wave-pulse-1 {
            animation-delay: .75s;
        }
    }
}



@keyframes zoomBig {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
      border-width: 3px;
    }
    40% {
      opacity: .5;
      border-width: 2px;
    }
    65% {
      border-width: 1px;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
      border-width: 1px;
    }
}







