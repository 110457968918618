/*=========================
    Icon Box Area 
==========================*/

.single-icon-box{
    &:last-child{
        .icon-box{
            .line{
                display: none;
            }
        }
    }
}

.icon-box {
    text-align: center;
    position: relative;
    .line {
        position: absolute;
        top: 60px;
        left: 100%;
        transform: translate(-50%, -5px);
        height: 10px;
        width: 100%;
        line-height: 0;
        .dot{
            display: inline-block;
            vertical-align: middle;
            background: #e4e8f6;
            border-radius: 50%;
            margin: 0 8px;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
            &.dot-1 {
                width: 2px;
                height: 2px;
            }

            &.dot-2 {
                width: 4px;
                height: 4px;
            }

            &.dot-3 {
                width: 6px;
                height: 6px;
            }

            &.dot-4 {
                width: 8px;
                height: 8px;
            }

            &.dot-5 {
                width: 10px;
                height: 10px;
            }
        }
    }
    .content {
        .icon {
            position: relative;
            width: 120px;
            height: 120px;
            line-height: 120px;
            border-radius: 40px;
            font-size: 34px;
            background: #fff;
            box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
            margin: 0 auto 52px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: #38cb89;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        h5 { 
            font-size: 24px;
            margin-bottom: 14px;
            color: #6d70a6;
            @media #{$sm-layout} {
                font-size: 19px;
                margin-bottom: 16px;
            }
            @media #{$md-layout} {
                font-size: 21px;
                margin-bottom: 12px;
            }
        }
        p {
            padding: 0 15px;

        }
    }
    &:hover{
        .content {
            .icon {
                color: #fff;
                background-color: #38cb89;
            }
        }
    }
}


/*=============================
    Icon Box Style Two 
==============================*/

.icon-box--2 {
    padding: 37px 30px;
    border-radius: 5px;
    @extend %transition;
    background: #ffffff;
    margin-top: 30px;

    @media #{$sm-layout} {
        padding: 20px 20px;
        margin-top: 0;
    }

    @media #{$sm-layout} {
        text-align: center;
    }
    .content {
        display: flex;
        align-items: center;

        @media #{$sm-layout} {
            display: block;
        }

        @media #{$sm-layout} {
            text-align: center;
        }
        .icon {
            position: relative;
            flex-shrink: 0;
            margin: 0 27px 0 0;
            border: 3px solid #f5f5f5;
            height: 114px;
            width: 114px;
            line-height: 114px;
            text-align: center;
            border-radius: 50%;

            @media #{$sm-layout} {
                margin-bottom: 20px;
            }
            @media #{$sm-layout} {
                margin: 0 auto;
                margin-bottom: 20px;
            }
            img {
                max-width: 60px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .inner {
            flex-grow: 1;
            position: relative;
            z-index: 1;
            h5 { 
                color: #6d70a6;
                font-size: 18px;
                margin-bottom: 14px;
            }
            p { 
                text-align: left;
                @media #{$sm-layout} {
                    text-align: center;
                }
            }
        }
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1);
    }
}


/*=============================
    Box Icon Style Two 
==============================*/

.box-icon-two-wrapper{
   .container{
        max-width: 970px;
   }
}

.box-icon--2 {
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    @extend %transition;
    cursor: pointer;
    .content {
        .icon-inner {
            margin: 0 auto;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            border-radius: 26px;
            font-size: 30px;
            margin-bottom: 19px;
            color: #2bc1db;
            border-color: #2bc1db;
            background-color: #eaf9ff;
            &.window{
                color: #ffa301;
                border-color: #ffa301;
                background-color: #fff6dc;
            }
            &.apple{
                color: #524ce8;
                border-color: #524ce8;
                background-color: #f5f4fe;
            }

            &.java{
                color: #ff595d;
                border-color: #ff595d;
                background-color: #fff5f6;
            }
            &.android{
                color: #8dc938;
                border-color: #8dc938;
                background-color: #f5faed;
            }
            &.php{
                color: #3066f0;
                border-color: #3066f0;
                background-color: #eaf0fe;
            }

        }
        .inner {
            h5 { 
                font-size: 15px;
                margin-bottom: 0;
                color: #696969;
                font-weight: 400;
            }
        }
    }
    &:hover{
        background: #fff;
        box-shadow: 0 10px 55px 5px rgba(51, 51, 51, 0.09);
        transform: translateY(-5px);
    }
}


/*=============================
    Box Icon Style Three
==============================*/

.box-icon--3 {
    padding: 35px 40px 49px;
    box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
    background: #fff;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before{
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
    }
    .icon {
        position: relative;
        background: #f6f5f9;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        margin-bottom: 16px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        img {
            max-width: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .content {
        h5 {
            &.heading {
                color: #6d70a6;
                font-size: 18px;
                margin-bottom: 16px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
        p {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
    &:hover {
        color: #fff;
        transform: translateY(-5px);
        .icon {
            background: rgba(246, 245, 249, 0.2);
        }
        .content {
            h5 {
                &.heading {
                    color: #ffffff;
                }
            }
            p {
                color: #ffffff;
            }
        }
        
        &::before{
            opacity: .7;
            visibility: visible;
            background-color: #9C7AF2;
            background-image: linear-gradient(-138deg,#9C7AF2 0,#5E61E7 100%);
        }
    }
}


/*=============================
    Box Icon Style Four
==============================*/

.box-icon--4 {
    text-align: center;
    border-radius: 5px;
    padding: 36px 35px 41px;
    @extend %transition;
    @media #{$sm-layout} {
        padding: 15px 10px;
    }
    .icon {
        margin-bottom: 21px;
        img {
           
        }
    }
    .content {
        h5 {
            &.heading {
                color: #6d70a6;
                font-size: 18px;
                margin-bottom: 14px;
                font-weight: 400;
            }
        }
       
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
        border-color: #fff;
    }
}



/*=============================
    Box Icon Style Five
==============================*/

.box-icon--5 {
    padding: 37px 30px 37px 17px;
    box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
    background: #fff;
    border-radius: 10px;
    text-align: left;
    display: flex;
    align-items: center;
    height: 100%;
    @media #{$large-mobile} {
        display: block;
    }
    .icon {
        position: relative;
        flex-shrink: 0;
        margin: 0 13px 0 0;
        min-width: 185px;
        text-align: center;
        @media #{$large-mobile} {
            margin-bottom: 15px;
        }

        @media #{$md-layout} {
            min-width: auto;
            width: 88px;
        }
        @media #{$sm-layout} {
            min-width: auto;
            width: 88px;
        }
        img {
            height: 127px;
            width: 100%;

            @media #{$md-layout} {
                height: 86px;
                width: 100%;
            }
            @media #{$sm-layout} {
                height: 86px;
                width: 100%;
            }
        }
    }
    .content {
        flex-grow: 1;
        h5 {
            &.heading {
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 12px;
            }
        }
       
    }
    &:hover {
        
    }
}



/*=============================
    Box Icon Style Six
==============================*/

.box-icon--6 {
    border-radius: 5px;
    padding: 48px 35px 51px;
    @extend %transition;
    text-align: center;
    @media #{$sm-layout} {
        padding: 10px;
    }
    .icon {
        margin-bottom: 33px;
        @media #{$sm-layout} {
            margin-bottom: 12px;
        }
        @media #{$md-layout} {
            margin-bottom: 12px;
        }
    }
    .content {
        span{
            color: rgba(89,69,230,0.5);
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 17px;
            display: inline-block;
        }
        h5 {
            &.heading {
                font-size: 18px;
                margin-bottom: 21px;
                @media #{$sm-layout} {
                    margin-bottom: 12px;
                }
                @media #{$md-layout} {
                    margin-bottom: 12px;
                }
            }
        }
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
        border-color: #fff;
    }
}

/*=============================
    Box Icon Style Seven
==============================*/

.box-icon--7 {
    padding: 67px 40px 64px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 16px 40px #ededed;
    text-align: center;
    @extend %transition;
    .svg-inner {
        height: 90px;
        text-align: center;
        width: 70px;
        margin: 0 auto;
        svg{
            width: 200px;
            stroke: #ff8b8b;
            stroke-width: 2;
            margin: 0 auto;
        }
    }
    .content {
        h5 {
            &.heading {
                line-height: 2;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 3px;
                text-transform: uppercase;
                margin-bottom: 17px;
            }
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}



/*=============================
    Box Icon Style Seven
==============================*/

.box-icon--9 {
    @extend %transition;
    text-align: center;
    padding: 60px 25px;
    background: #fff;
    border-bottom: 3px solid #fd1c25;
    position: relative;
    z-index: 1;
    .svg-inner {
        height: 90px;
        text-align: center;
        width: 70px;
        margin: 0 auto;
        svg{
            path{
                width: 200px;
                stroke: #fd1c25;
                stroke-width: 2;
                margin: 0 auto;
                transition: 0.8s;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 280;
                stroke-dashoffset: 0;
            }
        }
    }
    .content {
        h5 {
            &.heading {
                line-height: 1.25;
                font-size: 20px;
                margin-bottom: 30px;
                @extend %transition;
            }
        }
        p {
            @extend %transition;
        }
    }
    &::before{
        color: #fff;
        background-color: #9C7AF2;
        background-image: linear-gradient(-129deg,#9C7AF2 0,#5E61E7 100%);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -3px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    &:hover {
        transform: translateY(-5px);
        .svg-inner {
            svg{
                path{
                    // -webkit-animation: draw 10s linear alternate infinite;
                    // animation: draw 10s linear alternate infinite;
                    stroke: #ffffff;
                    stroke-dasharray: 99;
                    stroke-dashoffset: 0;
                }
            }
        }
        .content {
            h5 {
                &.heading {
                   color: #ffffff;
                }
            }
            p{
                color: #ffffff;
            }
        }
        &::before{
            opacity: 1;
            visibility: visible;
        }
    }
}



@-webkit-keyframes draw {
    0% {
      stroke-dashoffset: 0;
    }
    
    30% {
      stroke-dashoffset: 10;
    }

    50% {
      stroke-dashoffset: 20;
    }

    70% {
      stroke-dashoffset: 60;
    }

    100% {
      stroke-dashoffset: 99;
    }
}





.secret-link{
    padding: 41px 30px 45px 42px;
    border: 1px solid rgba(182, 183, 210, 0.5);
    box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
    background: #fff;
    border-radius: 5px;
    @extend %transition;
    @media #{$sm-layout} {
        padding: 20px 20px 20px 20px;
    }
    .secret-link-box{
        display: flex;
        .image{
            position: relative;
            flex-shrink: 0;
            margin: 0 20px 0 0;
            min-width: 120px;
            align-self: center;
            @media #{$lg-layout} {
                margin: 0 10px 0 0;
                min-width: 93px;
            }
            @media #{$sm-layout} {
                margin: 0 10px 0 0;
                min-width: 93px;
            }
            @media #{$sm-layout} {
                min-width: auto;
                width: 53px;
            }
            img{
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
        }
        .content{
            flex-grow: 1;
            h6 {
                color: #ababab;
                margin-bottom: 21px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 15px;
                @media #{$lg-layout} {
                    margin-bottom: 9px;
                }
                @media #{$md-layout} {
                    margin-bottom: 9px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 9px;
                }
            }
            h4 {
                font-size: 34px;
                line-height: 1.42;
                color: #333;
                margin-bottom: 13px;
                @media #{$lg-layout} {
                    font-size: 28px;
                    margin-bottom: 10px;
                }
                @media #{$md-layout} {
                    font-size: 28px;
                    margin-bottom: 10px;
                }
                @media #{$sm-layout} {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
            p {

            }
        }
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
        border-color: #fff;
    }
}

/*=============================
    Box Icon Style Seven
==============================*/

.box-icon--8 {
    .content-header{
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        i{
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 8px;
            font-size: 30px;
            color: #fff;
            background-image: linear-gradient(-135deg, #f8c59b 0%, #e68c60 50%, #f8c59b 100%);
            background-position: left center;
            background-size: 200% auto;
            flex-shrink: 0;
            margin-right: 19px;
            @extend %transition;
        }
        h5 {
            &.heading {
                color: #2e3280;
                font-size: 24px;
                margin-bottom: 0;
            }
        }
    }
    .content {
        p{

        }
    }
    &:hover {
        .content-header {
            i {
                background-position: right center;
            }
        }
    }
}




/*============================
    Circle Box 
==============================*/
.circle-box{
    width: 160px;
    height: 160px;
    background-color: #9C7AF2;
    background-image: linear-gradient(-224deg,#9C7AF2 0,#5E61E7 100%);
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    z-index: 2;
    .inner{
        background-image: linear-gradient(218deg, #ffffff 0, #ffffff 50%, #ffffff 100%);
        border-radius: 100%;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        position: absolute;
        .countinner{
            background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
            border-radius: 100%;
            left: 27px;
            right: 27px;
            top: 27px;
            bottom: 27px;
            position: absolute;
        }
    }
    .number-position{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 34px;
        line-height: 1;
        color: #fff;
        z-index: 2;
    }
    .circle-design {
        box-shadow: 0 2px 17px rgba(89,69,230,0.27);
        position: absolute;
        border-radius: 50%;
        animation: jump infinite 2s;
        animation-direction: alternate;
        background-color: #9C7AF2;
        background-image: linear-gradient(-224deg,#9C7AF2 0,#5E61E7 100%);
        &.one {
            width: 43px;
            height: 43px;
            left: -12px;
            bottom: 27px;
        }
        &.two {
            width: 17px;
            height: 17px;
            right: -8px;
            top: 50%;
            margin-top: -11px;
            animation-delay: 1s;
        }
    }
}

@keyframes jump {
    0% {
      transform: translateY(-10px) scale(0.9, 0.9);
    }
    100% {
      transform: translateY(10px) scale(1, 1);
    }
}
